import { ClipboardModule } from '@angular/cdk/clipboard';
import { DialogModule } from '@angular/cdk/dialog';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ApiModule, BASE_PATH } from '@gentext/api-client';
import { AuthConfiguration, AuthOfficeModule } from '@gentext/auth-office';
import { CHAT_SERVICE, ChatComponent, LICENSE_SERVICE } from '@gentext/chat-ui';
import { CookieConsentModule } from '@gentext/cookie-consent';
import { LoggingModule } from '@gentext/logging';
import {
  GET_DEFAULT_ERROR_MESSAGE,
  ReuseRoutesRouterStrategy,
} from '@gentext/office';
import { AlertsBannerComponent } from './alerts-banner/alerts-banner.component';
import { AppComponent } from './app.component';
import { routes } from './app.routes';
import { BillingRedirectComponent } from './billing-redirect/billing-redirect.component';
import { ChatAppComponent } from './chat-app/chat-app.component';
import { ChatUiService } from './chat-ui.service';
import { LicenseService } from './license.service';
import { ManagePlanComponent } from './manage-plan/manage-plan.component';
import { RedirectComponent } from './redirect/redirect.component';
import { TranslocoRootModule } from './transloco-root.module';
import { UpgradeAdComponent } from './upgrade-ad/upgrade-ad.component';
const authConfiguration: AuthConfiguration = {
  clientId:
    process.env['NX_AUTH_FINANCE_CLIENT_ID'] ||
    'e77caf36-acdd-4953-a9e7-cbd9e1e3b7dd',
  authority: `https://${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.b2clogin.com/${process.env['NX_FINANCE_AUTH_TENANT_NAME']}.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN`,
  apiBaseUrl: `https://${
    process.env['NX_API_FINANCE_HOST'] || 'localhost:7575'
  }`,
  tenantName: `${process.env['NX_FINANCE_AUTH_TENANT_NAME']}`,
};
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    ManagePlanComponent,
    RedirectComponent,
    BillingRedirectComponent,
    UpgradeAdComponent,
    ChatAppComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    ClipboardModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes, { initialNavigation: 'enabledBlocking' }),
    AuthOfficeModule.forRoot(authConfiguration),
    LoggingModule.forRoot({
      consoleEnabled: process.env['NX_CONSOLE_ENABLED'] === 'true',
    }),
    ApiModule,
    ChatComponent,
    DialogModule,
    TranslocoRootModule,
    AlertsBannerComponent,
    CookieConsentModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ReuseRoutesRouterStrategy,
    },
    {
      provide: CHAT_SERVICE,
      useClass: ChatUiService,
    },
    {
      provide: LICENSE_SERVICE,
      useClass: LicenseService,
    },
    {
      provide: BASE_PATH,
      useValue: `https://${process.env['NX_API_FINANCE_HOST']}`,
    },
    {
      provide: GET_DEFAULT_ERROR_MESSAGE,
      useValue: () => 'An error occurred. Please try again later.',
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
