import { Dialog } from '@angular/cdk/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BASE_PATH, DocumentService } from '@gentext/api-client';
import { AuthService } from '@gentext/auth-office';
import {
  ChatMessageResponse,
  ChatResponse,
  ChatService,
  ChatSystemResponse,
} from '@gentext/chat-ui';
import { LoggingService } from '@gentext/logging';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import * as signalR from '@microsoft/signalr';
import { Observable, Subject } from 'rxjs';
import { LicenseService } from './license.service';
import { UpgradeAdComponent } from './upgrade-ad/upgrade-ad.component';

@Injectable({
  providedIn: 'root',
})
export class ChatUiService implements ChatService {
  private hubUrl = `${this.baseUrl}/hubs/messageRelayHub`;
  private _clearMessages$ = new Subject<void>();
  public clearMessages$ = this._clearMessages$.asObservable();

  public clearMessages(): void {
    this._clearMessages$.next();
  }

  goToBottom() {
    window.scrollTo(0, document.body.scrollHeight);
  }

  sendChat(input: string, chatId: string): Observable<ChatResponse> {
    const response$ = new Subject<ChatResponse>();
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(this.hubUrl, {
        accessTokenFactory: () => this.auth.getAccessTokenInteractive(),
      })
      .build();
    connection.on('data', (data) => {
      this.logging.trace({
        message: 'Data received',
        properties: {
          data,
        },
        severityLevel: SeverityLevel.Verbose,
      });
      const res: ChatMessageResponse = {
        type: 'chatMessageResponse',
        message: data,
      };
      response$.next(res);
    });
    connection.on('ineligibleFeature', () => {
      const res: ChatSystemResponse = {
        type: 'chatSystemResponse',
        response: 'INELIGIBLE_FEATURE_ACCESSED',
      };
      response$.next(res);
    });
    connection.on('done', () => {
      this.logging.trace({
        message: 'Connection signalR done',
      });
      response$.complete();
    });
    connection.on('error', (err) => {
      const message = this.getErrorMessage(err);
      response$.error({
        err,
        message,
      });
    });

    connection
      .start()
      .then(() => {
        this.logging.trace({
          message: 'Chat Connection started',
        });
        connection.invoke('SendChatAsk', input, chatId, 'en');
      })
      .catch((err) => {
        this.logging.exception({
          exception: err,
        });
        response$.error({
          err,
          message: 'Something went wrong while setting up the connection.',
        });
      });
    return response$.asObservable();
  }

  updateDocumentSummary(chatId: string, summary: string) {
    // do nothing, just log
    this.logging.trace({
      message: 'Document Summary Ignored',
      properties: {
        chatId,
        summary,
      },
    });
  }
  uploadDocument(file: File, chatId: string): Observable<string> {
    const response$ = new Subject<string>();
    const supportedFileTypes = [
      'application/pdf',
      'text/plain',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'audio/mpeg',
      'audio/mp3',
      'audio/mp4',
      'audio/mpga',
      'audio/x-m4a',
      'audio/wav',
      'audio/webm',
      'audio/m4a',
    ];

    if (!supportedFileTypes.includes(file.type)) {
      response$.error(
        'Please upload a supported file type: .pdf, .txt, .docx, .mp3, .flac, .m4a, .mp4, .mpeg, .mpga, .oga, .ogg, .wav, .webm',
      );
      return response$.asObservable();
    }

    this.documentsService
      .documentUploadPost(chatId, file, 'body', true)
      .subscribe({
        next: (res) => {
          response$.next(res);
          response$.complete();
        },
        error: () => {
          response$.error('Something went wrong uploading the document. ');
          response$.complete();
        },
      });
    return response$.asObservable();
  }
  async handleFileInputClick(): Promise<boolean> {
    const license = await this.licenseService.getLicense();
    if (license?.planId === 'free') {
      this.dialog.open(UpgradeAdComponent, {
        width: '250px',
        autoFocus: 'dialog',
      });
      return false;
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getErrorMessage(err: any): string {
    this.logging.trace({
      message: 'An unsuccessful response from the API has occurred.',
      properties: { err },
      severityLevel: SeverityLevel.Warning,
    });

    const httpErrorResponse = err as HttpErrorResponse;
    let message =
      'We are sorry, but something went wrong on the AI service. Please try your request after a brief wait and contact us if the issue persists.';
    const apiErrorMessage: string =
      typeof err === 'string'
        ? err
        : httpErrorResponse.error?.error?.message ||
          (typeof httpErrorResponse.error === 'string'
            ? httpErrorResponse.error
            : typeof httpErrorResponse.message === 'string'
              ? httpErrorResponse.message
              : '');

    if (
      apiErrorMessage.includes('Connection closed with an error') ||
      apiErrorMessage.includes('OPENAI_TOKEN_ERROR')
    ) {
      message = `Maximum word input length exceeded. Please select a shorter text`;
    } else if (apiErrorMessage.includes('USAGE_EXCEEDED')) {
      message = `You have exceeded your free monthly usage limit. To continue using FinScribe, please upgrade by clicking the 'Manage Plan' button at the menu`;
    } else if (apiErrorMessage.includes('OPENAI_ERROR')) {
      message = `We are sorry, but the AI service is currently facing a server outage. We are working to fix the problem as soon as possible.`;
    }

    return message;
  }

  constructor(
    private auth: AuthService,
    private logging: LoggingService,
    private documentsService: DocumentService,
    private dialog: Dialog,
    private licenseService: LicenseService,
    @Inject(BASE_PATH) private baseUrl: string,
  ) {}
}
